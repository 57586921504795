<div class="footer">
    <div class="footerInner">
      
        <p class="mb-0">
          <img src="assets/images/logo.png" class="mr-2" alt="SGI Crowe" />
          Copyright © {{ date }}
          <a href="https://www.crowe.com/mx" target="_blank" rel="noopener noreferrer">
            Gossler S.C. miembro de Crowe Global
          </a>
          |
          <a href="https://www.crowe.com/mx/privacy-policy/" target="_blank" rel="noopener noreferrer">
            Aviso de Privacidad
          </a>
          |
          <span > {{ env }} </span>
        </p>
      </div>
</div>