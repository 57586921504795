<nz-layout>
    <nz-content>
        <cui-sidebar></cui-sidebar>
        <!-- <cui-support-chat></cui-support-chat> -->
        <div class="authContainer" [ngClass]="{
        'cui__layout__squaredBorders': isSquaredBorders,
        'cui__layout__cardsShadow': isCardShadow,
        'cui__layout__borderless': isBorderless,
        'white': authPagesColor === 'white',
        'gray': authPagesColor === 'gray'

      }" [ngStyle]="{backgroundImage: authPagesColor === 'image' ? 'url(assets/images/content/photos/7.jpg)' : ''}">
            <div class="topbar" [ngClass]="{
        'topbarGray': isGrayTopbar
      }">
                <div class="logoContainer">
                    <div class="logo">
                        <img src="assets/images/logo.png" class="mr-2" alt="Crowe México" />
                        <div class="name">{{ logo }}</div>
                        <div *ngIf="logo === 'Crowe'" class="descr">SGI</div>
                    </div>
                </div>
                <!-- <div class="d-none d-sm-block">
                    <span class="mr-2">Don't have an account?</span>
                    <a routerLink="/auth/register" class="font-size-16 kit__utils__link">
            Sign up
          </a>
                </div> -->
            </div>
            <div class="containerInner">
                <div [@slideFadeinUp]="routeAnimation(outlet, 'slideFadeinUp')" [@slideFadeinRight]="routeAnimation(outlet, 'slideFadeinRight')" [@zoomFadein]="routeAnimation(outlet, 'zoomFadein')" [@fadein]="routeAnimation(outlet, 'fadein')">
                    <router-outlet #outlet="outlet"></router-outlet>
                </div>
            </div>

        </div>
    </nz-content>
</nz-layout>