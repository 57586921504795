import { Component } from '@angular/core'
import { UserDataService } from 'src/app/services/api/user-data.service'
import { environment } from 'src/environments/environment'
import storeDataU from 'store';

@Component({
  selector: 'cui-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent {

  userData: any;

  constructor( 
    private dataService: UserDataService 
    )
  {
    this.loadTimer();
  }

  loadTimer(){

    if(storeDataU.get('userDataDB') != null){
      this.userData = storeDataU.get('userDataDB')[0];
      setInterval( () => {
        this.dataService.getActiveCounter(this.userData.id, this.userData.zonaHoraria).subscribe( data => {
          environment.counterActive = data[0]['flag'];
        })
       
      }, 2000)
    }

    
  }
}
