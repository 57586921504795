import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, LOCALE_ID } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { NgProgressModule } from "@ngx-progressbar/core";
import { NgProgressRouterModule } from "@ngx-progressbar/router";
import { NgProgressHttpModule } from "@ngx-progressbar/http";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule, SETTINGS } from "@angular/fire/firestore";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { reducers, metaReducers } from "./store/reducers";
import { UserEffects } from "./store/user/effects";
import { firebaseConfig, firebaseAuthService } from "./services/firebase";
import { jwtAuthService } from "./services/jwt";
import { MockHttpCallInterceptor } from "./services/fakeApi";

//librerias agregadas

import { SocketIoModule, SocketIoConfig } from "ngx-socket-io";
import { environment } from "../environments/environment";
const config: SocketIoConfig = {
  url: environment.wsUrl,
  options: {}
};
import { DatePipe } from "../../node_modules/@angular/common";

// locale resistration
import { registerLocaleData } from "@angular/common";
import { default as localeEn } from "@angular/common/locales/es-MX";
import { NZ_I18N, es_ES as localeZorro, IconConfig } from "ng-zorro-antd";

import { NgxMaskModule, IConfig } from 'ngx-mask'

const maskConfig: Partial<IConfig> = {
  validation: false,
};



const LOCALE_PROVIDERS = [
  { provide: LOCALE_ID, useValue: "es" },
  { provide: NZ_I18N, useValue: localeZorro }
];
registerLocaleData(localeEn, "es");

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,

    // translate
    TranslateModule.forRoot(),

    // ngrx
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([UserEffects]),
    StoreRouterConnectingModule.forRoot(),

    // nprogress
    NgProgressModule.withConfig({
      thick: false,
      spinner: false,
      color: "#FDB913",
      meteor: true
    }),
    NgProgressRouterModule,

    // init firebase
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,

    //sockets
    SocketIoModule.forRoot(config),

    NgxMaskModule.forRoot()
  ],
  providers: [
    // auth services
    firebaseAuthService,
    jwtAuthService,

    // fake http interceptors
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MockHttpCallInterceptor,
      multi: true
    },

    // locale providers
    ...LOCALE_PROVIDERS,

    // firestore settings
    { provide: SETTINGS, useValue: {} },

    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
