import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core'
import { Router, NavigationStart } from '@angular/router'
import { Store } from '@ngrx/store'
import { filter } from 'rxjs/operators'
import { reduce } from 'lodash'
import { MenuDataService } from 'src/app/services/api/menu-data.service'
import { environment } from 'src/environments/environment'

import storeDataU from 'store'

@Component({
  selector: 'cui-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit {

  menuData: any[];
  breadcrumbs: any[];
  activeCount: boolean;

  constructor(
    private store: Store<any>,
    private router: Router,
    private menuDataService: MenuDataService,
  ) {}

  async ngOnInit() {
    let userDB = storeDataU.get('userDataDB');    
    await new Promise<void>( resolve => {      
      this.menuDataService.getMenuData(userDB[0]['id'], userDB[0]['ReservacionActivo']).subscribe( menu => {

        this.menuData = menu[0];
        
        let arr = this.router.url.split("/")

        this.menuData.push(
          {
            icon: 'icmn-user',
            key: '',
            title: 'Mi Perfil',
            url: '/mi-perfil'
          },
          {
            icon: 'icmn-pie-chart',
            key: '',
            title: 'CRM',
            children: [
              {
                key: '',
                title: 'Oportunidades', 
                url: '/crm/opportunity/'+arr[3]+'/'+arr[4]+''
              },
              {
                key: '',
                title: 'Oportunidadad Aceptada',
                url: '/crm/opportunity/accepted'
              }
            ]
          },
          {
            icon: 'icmn-books',
            key: '',
            title: 'Capacitación',
            children: [
              {
                key: '',
                title: 'Capacitaciones',
                url: '/capacitacion/capacitaciones/' + arr[3]
              }
            ]
          },
          )
        resolve()
      })
      return this.menuData
    })
    
    this.generateBreadcrumbs(this.router.url)
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        this.generateBreadcrumbs(event.url ? event.url : null)
      })
      this.checkActiveCounter();
  }

  generateBreadcrumbs(event: any) {
    this.breadcrumbs = this.getPath(this.menuData, event).reverse()
  }

  getPath(data: any[], url: string, parents = []) {
    const items = reduce(
      data,
      (result: any, entry: any) => {
        if (result.length) {
          return result
        }
        if (entry.url === url) {
          return [entry].concat(parents)
        }
        if (entry.children) {
          const nested = this.getPath(entry.children, url, [entry].concat(parents))
          return (result || []).concat(nested.filter((e: any) => !!e))
        }
        return result
      },
      [],
    )
    return items.length > 0 ? items : [false]
  }

  checkActiveCounter(){
    setInterval( () =>  {
      this.activeCount = environment.counterActive;
    },1000)

  }


}
