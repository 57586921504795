<div class="doNotPrint" >

    <!-- Botones flotantes  -->
    <a *ngIf="false" href="javascript: void(0);" style="bottom: calc(50% - 360px)" (click)="setTheme(theme === 'default' ? 'dark' : 'default')" nzTooltipTitle="Tema oscuro" nzTooltipPlacement="left" nz-tooltip class="cui__sidebar__toggleButton">
        <i *ngIf="theme === 'default'" class="fe fe-moon"></i>
        <i *ngIf="theme !== 'default'" class="fe fe-sun"></i>
    </a>
    <!-- <a href="https://docs.cleanuitemplate.com" target="_blank" rel="noopener noreferrer" style="bottom: calc(50% - 360px)" nzTooltipTitle="Documentación" nzTooltipPlacement="left" nz-tooltip class="cui__sidebar__toggleButton">
        <i class="fe fe-book-open"></i>
    </a> -->
</div>