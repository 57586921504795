import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class MenuDataService {

  private serverUrl: String;
  headers : any;

  constructor(
    private http: HttpClient
  ) { 
    this.serverUrl = environment.urlServe+'userData';
  }

  getMenuData(idUser: number, reservation){
  
    let params = new HttpParams().set('idUserDB', String(idUser));
    params = params.append('reservation', reservation);
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');

    const path = `${this.serverUrl}/menu.php`;
    return this.http.post(path, params , this.headers );


  }

}
