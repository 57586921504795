<div>
    <div nz-dropdown nzTrigger="click" class="dropdown" [nzDropdownMenu]="menu" (nzVisibleChange)="badgeCountIncrease()">
        <nz-badge [nzCount]="badgeCount">
            <nz-avatar [nzShape]="'square'" [nzSize]="'large'" class="avatar" [nzSrc]="avatar"></nz-avatar>
        </nz-badge>
    </div>
    <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
            <li nz-menu-item>
                <strong>{{ 'topBar.profileMenu.hello' | translate }}, {{name || 'Anonymous'}}</strong>
                <div>
                    <strong>
          {{ 'topBar.profileMenu.job' | translate }}:
        </strong> {{job || '—'}}
                </div>
            </li>
            <li nz-menu-divider></li>
            <li nz-menu-item>
                <div>
                    <strong>{{ 'topBar.profileMenu.email' | translate }}: </strong> {{email || '—'}}
                    <br />
                    <strong>{{ 'topBar.profileMenu.office' | translate }}: </strong> {{office || '—'}}
                </div>
            </li>
            <li nz-menu-divider></li>
            <li nz-menu-item routerLink='/mi-perfil'>
                <a href="javascript: void(0);">
                    <i class="fe fe-user mr-2"></i> {{ 'Mi Perfil' }}
                </a>
            </li>
            <li nz-menu-divider></li>
            <li nz-menu-item>

                <a href="javascript: void(0);" (click)="logout()">
                    <i class="fe fe-log-out mr-2"></i> {{ 'topBar.profileMenu.logout' | translate }}
                </a>
            </li>
        </ul>
    </nz-dropdown-menu>
</div>