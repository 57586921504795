import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { UserData } from './../interfaces/user-data';

import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  private serverUrl: String;
  public usIdDB: string;
  headers : any;
  
  constructor(
    private http: HttpClient,
  ) {
    this.serverUrl = environment.urlServe+'userData';
   }

   getUserData(data: string, avatar: string){

    let params = new HttpParams().set('email', data);
    params = params.append("avatar", avatar);

    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');

    const path = `${this.serverUrl}/userData.php`;
    return this.http.post( path, params, this.headers);


   }

   getActiveCounter(id, zonaHoraria){
    let params = new HttpParams().set('id', id);
    params = params.append('zonaHoraria', zonaHoraria);

    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');

    const path = `${this.serverUrl}/activeCounter.php`;
    return this.http.post( path, params, this.headers );
   }
}
