import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortPipe'
})
export class OrderPipe implements PipeTransform {

  constructor() { }
  
  transform(value: any[], propertyName: string): any[] {
    if (!value || !value.length) return [];
    
    return value.sort((a, b) => this.compare(a[propertyName], b[propertyName]));
  }

  private compare(a: string, b: string): number {
    // Remover acentos y convertir a minúsculas para la comparación
    const normalizeString = (str: string) => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

    const normalizedA = normalizeString(a);
    const normalizedB = normalizeString(b);

    if (normalizedA < normalizedB) {
      return -1;
    }
    if (normalizedA > normalizedB) {
      return 1;
    }
    return 0;
  }

}
