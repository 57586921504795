import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccessService {

  private serverUrl: String;
  public usIdDB: string;
  headers : any;

  constructor(private http: HttpClient) { 
    this.serverUrl = environment.urlServe+'userData';
  }

  getAccess(email) {
    let params = new HttpParams().set('email', email);
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');

    const path = `${this.serverUrl}/access.php`;
    return this.http.post(path, params , this.headers );
  }
}
