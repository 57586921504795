import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'cui-record-hours',
  templateUrl: './record-hours.component.html',
  styleUrls: ['./record-hours.component.scss']
})
export class RecordHoursComponent implements OnInit {

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  goToRegistroHoras(){
    this.router.navigate(['/control-tiempos/registro-horas']);
  }

}
