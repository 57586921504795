<!-- <div class="breadcrumbs">
    <div class="path">
        <a href routerLink="/">SGI</a>
        <ng-container *ngFor="let item of breadcrumbs; let last = last;">
            <span *ngIf="!last">
        <span class="arrow"></span>
            <span>{{ item.title }}</span>
            </span>
            <span *ngIf="last">
        <span class="arrow"></span>
            <strong class="current">{{ item.title }}</strong>
            </span>
        </ng-container>
    </div>
    <app-modals></app-modals>
</div> -->

<div class="subbar">
  <ul class="breadcrumbsList mr-4">
    <li class="breadcrumbItem">
      <a href="#" class="breadcrumbLink">
        SGI
      </a>
    </li>
    <ng-container *ngFor="let item of breadcrumbs; let last = last;">
      <li *ngIf="!last" class="breadcrumbItem">
        <span class="breadcrumbLink">
          {{ item.title }}
        </span>
      </li>
      <li *ngIf="last" class="breadcrumbItem">
        <span class="breadcrumbLink breadcrumbLink__current"  style=" color: #fdb913;">
          {{ item.title }}
        </span>
      </li>
    </ng-container>
  </ul>
  <div class="divider mr-4 d-none d-xl-block">
    
  </div>
 
  <div class="amount mr-3 ml-auto d-none d-sm-flex"   > 
    <div *ngIf="activeCount" > 
      <a routerLink="/control-tiempos/registro-horas">
        <img src="/assets/images/resources/control-tiempos/hourGlass.gif" placement="left" ngbTooltip="GOS830601GE2 - GOSSLER S.C.
        NAL000001CG00000001 - Servicios Internos Gossler - [Proyectos Internos]
        Administración - Supervisión de personal limpieza" tooltipClass="custom-tooltip" height="30px"  >
      </a> 
    </div>
  </div>
  
  <div class="amount d-none d-sm-flex">

  </div>
</div>