export default {
    'topBar.issuesHistory': 'Historial de problematicas',
    'topBar.projectManagement': 'Administrar Proyectos',
    'topBar.typeToSearch': 'Buscar...',
    'topBar.findPages': 'Encontrar paginas...',
    'topBar.actions': 'Acciones',
    'topBar.status': 'Estatus',
    'topBar.profileMenu.hello': 'Hola',
    'topBar.profileMenu.billingPlan': 'Plan de facturación',
    'topBar.profileMenu.role': 'Rol',
    'topBar.profileMenu.email': 'Email',
    'topBar.profileMenu.phone': 'Teléfono',
    'topBar.profileMenu.editProfile': 'Editar Perfil',
    'topBar.profileMenu.logout': 'Salir',
    'topBar.profileMenu.job': 'Puesto',
    'topBar.profileMenu.office': 'Oficina',
}