import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SharedModule } from 'src/app/shared.module'
import { LayoutsModule } from 'src/app/layouts/layouts.module'
import { AppPreloader } from 'src/app/app-routing-loader'
import { AuthGuard } from 'src/app/components/cleanui/system/Guard/auth.guard'

// layouts & notfound
import { LayoutAuthComponent } from 'src/app/layouts/Auth/auth.component'
import { LayoutMainComponent } from 'src/app/layouts/Main/main.component'

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: LayoutMainComponent,
    children: [
      {
        path: 'dashboard',
        canActivate: [AuthGuard],        
        loadChildren: () =>
        import('src/app/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'capital-humano',    
        canActivate: [AuthGuard],     
        loadChildren: () =>
        import('src/app/pages/capital-humano/capital-humano.module').then(m => m.CapitalHumanoModule),
      },
      {
        path: 'catalogos',     
        canActivate: [AuthGuard],   
        loadChildren: () => 
        import('src/app/pages/catalogos/catalogos.module').then(m => m.CatalogosModule),
      },
      {
        path: 'configuracion',
        canActivate: [AuthGuard],
        loadChildren: () => 
        import('src/app/pages/configuracion/configuracion.module').then(m => m.ConfiguracionModule),
      },
      {
        path: 'clientes',     
        canActivate: [AuthGuard],   
        loadChildren: () => 
        import('src/app/pages/clientes/clientes.module').then(m => m.ClientesModule),
      },
      {
        path: 'control-tiempos',
        canActivate: [AuthGuard],
        loadChildren: () => 
        import('src/app/pages/control-tiempos/control-tiempos.module').then(m => m.ControlTiemposModule),
      },
      {
        path: 'estructura',
        canActivate: [AuthGuard],
        loadChildren: () => 
        import('src/app/pages/estructura/estructura.module').then(m => m.EstructuraModule),
      },
      {
        path: 'proyectos',
        canActivate: [AuthGuard],
        loadChildren: () => 
        import('src/app/pages/proyectos/proyectos.module').then(m => m.ProyectosModule),
      },
      {
        path: 'reportes',
        canActivate: [AuthGuard],
        loadChildren: () => 
        import('src/app/pages/reportes/reportes.module').then(m => m.ReportesModule ),
      },
      {
        path: 'planeacion-estrategica',
        canActivate: [AuthGuard],
        loadChildren: () => 
        import('src/app/pages/planeacion-estrategica/planeacion-estrategica.module').then(m => m.PlaneacionEstrategicaModule),
      },
      {
        path: 'reservar-lugar',
        canActivate: [AuthGuard],
        loadChildren: () => 
        import('src/app/pages/reservar-lugar/reservar-lugar.module').then(m => m.ReservarLugarModule),
      },
      {
        path:'crm',
        canActivate: [AuthGuard], 
        loadChildren: () => 
        import('src/app/pages/crm/crm.module').then(m => m.CrmModule),
      },
      {
        path:'mkt',
        canActivate: [AuthGuard],
        loadChildren: () => 
        import('src/app/pages/marketing/marketing.module').then(m => m.MarketingModule),
      },
      {
        path:'enlace',
        canActivate: [AuthGuard],
        loadChildren: () =>
        import('src/app/pages/enlace/enlace.module').then( m => m.EnlaceModule)
      },
      {
        path:'capacitacion',
        canActivate: [AuthGuard],
        loadChildren: () =>
        import('src/app/pages/capacitacion/capacitacion.module').then( m => m.CapacitacionModule)      
      },
      {
        path: 'gastos',
        canActivate: [AuthGuard],
        loadChildren: () => import('src/app/pages/gastos/gastos.module').then(m => m.GastosModule)
      },
      {
        path: 'vacantes',
        canActivate: [AuthGuard],
        loadChildren: () => import('src/app/pages/vacantes/vacantes.module').then(m => m.VacantesModule)
      },
      {
        path: 'equipo-computo',
        canActivate: [AuthGuard],
        loadChildren: () => import('src/app/pages/equipo-computo/equipo-computo.module').then(m => m.EquipoComputoModule)
      },
      {
        path: 'mi-perfil',
        canActivate: [AuthGuard],
        loadChildren: () => import('src/app/pages/perfil/perfil.module').then(m => m.PerfilModule)
      },
      {
        path: 'vcard',
        canActivate: [AuthGuard],
        loadChildren: () => import('src/app/pages/vcard/vcard.module').then(m => m.VcardModule)
      },
    ],
  },
  {
    path: 'auth',
    component: LayoutAuthComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('src/app/pages/auth/auth.module').then(m => m.AuthModule),
      },
    ],
  },
  {
    path: 'diploma',
    loadChildren: () => import('src/app/pages/diplomas/diplomas.module').then(m => m.DiplomasModule)
  },
  {
    path: '**',
    redirectTo: '/auth/404',
  },
]

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: AppPreloader,
    }),
    LayoutsModule,
  ],
  providers: [AppPreloader],
  exports: [RouterModule],
})
export class AppRoutingModule {}
